import React from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, AtomicBlockUtils } from "draft-js";

const DraftEditor = ({ value, onDescriptionChange, isDisable, isReadOnly, defaultSize }) => {
  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({ data: { link: reader.result } });
      reader.onerror = (error) => reject(error);
    });
  };

  const handleEditorDrop = async (e) => {
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith("image")) {
        const newFile = await uploadImageCallBack(file);
        const entityData = {
          src: newFile.data.link,
          height: "400px",
          width: "auto"
        };

        // Find and remove the old atomic block
        let newEditorState = value;
        let contentState = newEditorState.getCurrentContent();
        let blockMap = contentState.getBlockMap();
        blockMap.forEach((block) => {
          if (block.getType() === "atomic") {
            const entityKey = block.getEntityAt(0);
            if (entityKey) {
              const entity = contentState.getEntity(entityKey);
              if (entity && entity.getData().src === entityData.src) {
                const newBlockMap = blockMap.delete(block.getKey());
                contentState = contentState.merge({
                  blockMap: newBlockMap,
                  selectionAfter: newEditorState.getSelection()
                });
                newEditorState = EditorState.push(newEditorState, contentState, "remove-block");
              }
            }
          }
        });

        // Insert the new atomic block
        newEditorState = AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          newEditorState.getCurrentContent().createEntity("IMAGE", "MUTABLE", entityData).getLastCreatedEntityKey(),
          " "
        );

        onDescriptionChange(newEditorState);
      }
    }
  };

  return (
    <div className="editor-wrapper" onDrop={handleEditorDrop}>
      <Editor
        editorState={value}
        readOnly={isDisable || isReadOnly}
        toolbarHidden={isDisable || isReadOnly}
        editorClassName={isReadOnly ? "" : "editor-class"}
        toolbarClassName="toolbar-class"
        onEditorStateChange={(editorState) => onDescriptionChange(editorState)}
        toolbar={{
          options: ["inline", "colorPicker", "list", "remove", "link", "image"],
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"]
          },
          list: {
            options: ["unordered", "ordered"]
          },
          colorPicker: {
            colors: [
              "rgb(205, 141, 229)", // CD8DE5Є
              "rgb(255, 142, 212)", // FF8ED4
              "rgb(255, 70, 44)", // FF462C
              "rgb(255, 184, 0)", // FFB800
              "rgb(78, 207, 0)", // 4ECF00
              "rgb(0, 163, 255)", // 00A3FF
              "rgb(241, 205, 255)", // F1CDFF
              "rgb(255, 218, 241)", // FFDAF1
              "rgb(255, 206, 200)", // FFCEC8
              "rgb(255, 233, 176)", // FFE9B0
              "rgb(198, 255, 163)", // C6FFA3
              "rgb(208, 238, 255)",
              "rgb(0, 0, 0)" // D0EEFF
            ]
          },
          link: {
            options: ["link"]
          },
          image: {
            defaultSize: defaultSize
              ? defaultSize
              : {
                  width: "auto",
                  height: "400px"
                },
            previewImage: true,
            alt: { present: true, mandatory: false },
            uploadCallback: (file) => uploadImageCallBack(file)
          }
        }}
        editorStyle={{
          fontSize: "16px",
          backgroundColor: isDisable ? "#e9ecef" : "",
          borderRadius: isDisable ? "6px" : "",
          height: isDisable ? "37.6px" : ""
        }}
      />
    </div>
  );
};

DraftEditor.propTypes = {
  onDescriptionChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  isDisable: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool,
  defaultSize: PropTypes.object
};

export default DraftEditor;
