import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { getAgileDoc } from "API/agiles";
import { setError } from "redux/workspaceSlice";
import useGetAgileSectionsData from "hooks/useGetAgileSectionData";
import { useDispatch } from "react-redux";
import { FaPrintButton } from "components/IconButton";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Linkify from "components/Linkify";
import AttachmentLink from "components/AttachmentLink";
import { Spinner } from "react-bootstrap";

const AgileView = ({ projectId }) => {
  const dispatch = useDispatch();
  const pdfContentRef = useRef(null);
  const [docData, setDocData] = useState();
  const [sectionsInfoData, setSectionsInfoData] = useState([]);
  const noAgileDoc = "No Agile Document added yet.";
  const [projectAgileSections, isAgileDocLoading] = useGetAgileSectionsData(projectId);
  // const [isLoading, setIsLoading] = useState(false);
  const [attachmentFiles, setAttachmentFiles] = useState([]);

  useEffect(() => {
    projectAgileSections.length !== 0 && getAgileDocsData();
  }, [projectAgileSections]);

  const getAgileDocsData = async () => {
    try {
      // setIsLoading(true);
      await getAgileDoc(null, projectId)
        .then((res) => {
          // console.log(res, "getAgilesDocs");
          if (res.data.error) {
            dispatch(
              setError({
                isShow: true,
                title: "Error",
                message: res.data.error
              })
            );
            return;
          }
          const {
            attachment01ISfile,
            attachment02ISfile,
            attachment03ISfile,
            attachment04ISfile,
            attachment01_name,
            attachment02_name,
            attachment03_name,
            attachment04_name
          } = res.data.doc;
          setAttachmentFiles([
            { link: attachment01ISfile, name: attachment01_name },
            { link: attachment02ISfile, name: attachment02_name },
            { link: attachment03ISfile, name: attachment03_name },
            { link: attachment04ISfile, name: attachment04_name }
          ]);
          console.log(res.data.doc, "res.data.doc");
          setDocData(res.data.doc);
          const sectionDecriptions = JSON.parse(res.data.doc.textISsmallplaintextbox);
          const updatedArray = projectAgileSections?.map((item) => {
            const text = sectionDecriptions[item.name] || "";
            return { ...item, text };
          });
          // console.log(updatedArray, "updatedArray")
          setSectionsInfoData(updatedArray);
        })
        .catch((err) => console(err, "Error-catch getAgilesData"));
      // .finally(() => setIsLoading(false));
    } catch (err) {
      console.log(err, "Error getAgilesData");
    }
  };

  const handlePrintToPdf = () => {
    const content = pdfContentRef.current;
    html2canvas(content, { scale: 2, useCORS: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = pdf.internal.pageSize.getHeight();
        const ratio = pdfWidth / canvas.width;
        const margin = 10;

        pdf.addImage(imgData, "PNG", margin, margin, pdfWidth - margin * 2, canvas.height * ratio - margin * 2, "", "FAST");
        pdf.save("document.pdf");
      })
      .catch((error) => {
        console.error("Помилка при генерації зображення:", error);
      });
  };

  const formatText = (text) => {
    return text.replace(/\\n/g, "<br />").replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
  };

  return (
    <>
      {isAgileDocLoading ? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {projectAgileSections.length !== 0 ? (
            <div ref={pdfContentRef} id="pdf-content" className="pb-5">
              <div className="d-flex w-100 justify-content-between mb-3">
                <h2 style={{ fontSize: "16pt", fontWeight: 700 }}>{docData?.name}</h2>
                <FaPrintButton variant="outline-secondary" clickHandler={handlePrintToPdf} styles={{ width: "36px" }} />
              </div>
              {sectionsInfoData.map((section) => (
                <div className="mb-3" key={section.name}>
                  <div style={{ fontSize: "18px", fontWeight: 600 }}>{section.name}</div>
                  <div
                    style={{
                      fontSize: "14px",
                      width: "100%",
                      textAlign: "justify",
                      whiteSpace: "pre-wrap"
                    }}
                  >
                    <Linkify>{formatText(section.text)}</Linkify>
                  </div>
                </div>
              ))}
              <div className="d-flex">
                {attachmentFiles.map(
                  (file) =>
                    file.link && (
                      <AttachmentLink link={file.link} key={file.link} className="me-2">
                        {file.name}
                      </AttachmentLink>
                    )
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between align-content-center w-100 pb-5">
              <p style={{ fontSize: "20px", margin: "0px" }}>{noAgileDoc}</p>
            </div>
          )}
        </>
      )}
    </>
  );
};
AgileView.propTypes = {
  projectId: PropTypes.string
};
export default AgileView;
